import styled from 'styled-components';
import { Flex, Box } from '@strikelabs/luna';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme, darkMode }) =>
    darkMode && theme.colors.backgroundDark};
`;

export const Inner = styled(Container)`
  margin-bottom: 0;
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;
  margin: ${({ theme }) => `${theme.spacing.xl} 0`};

  ${({ theme }) => theme.media.smallTablet} {
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.xxl} 0`};
  }
`;

export const InnerContainer = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.smallTablet} {
    align-items: center;
    width: 50%;
    align-items: ${({ left }) => (left ? 'flex-start' : 'center')};
    padding-right: ${({ theme, left }) =>
      left ? theme.defaults.gutterSpacing : 0};
  }
`;

export const ImageContainer = styled(Box).attrs({ mb: [2, 2, 4, 4, 5] })`
  width: 105px;

  ${({ theme }) => theme.media.tablet} {
    width: 173px;
  }
`;
