import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

/**
 * @param src The filename of the image.
 * If the image is inside of a folder, then that
 * folder should be included in the string.
 *
 * `src="example/test-image.png"`
 */
const Image = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                formats: [WEBP, JPG, AUTO]
                placeholder: BLURRED
                quality: 80
              )
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  const image = getImage(match.node.childImageSharp);

  return <GatsbyImage image={image} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string,
};

export default Image;
