import React, { useState } from 'react';
import { Box, Text, Flex } from '@strikelabs/luna';
import { formatCurrency } from '@strikelabs/pax';
import { createEvent } from '@strikelabs/sputnik';
import PropTypes from 'prop-types';

import Slider from 'components/Slider';

import {
  Container,
  SelectWrapper,
  CalcTitle,
  ComissionSelect,
  SavingText,
  SavingsBox,
} from './style';

const range = [
  { from: 10000, to: 100000, increment: 5000 },
  { from: 100000, to: 1000000, increment: 10000 },
  { from: 1000000, to: 3000000, increment: 50000 },
  { from: 3000000, to: 5100000, increment: 100000 },
];

const createIncrementsBetween = ({ from, to, increment }) => {
  let numbers = [];
  for (let i = from; i < to; i += increment) {
    numbers.push(i);
  }
  return numbers;
};

const getCommissionRates = () => {
  const rates = createIncrementsBetween({
    from: 0,
    to: 0.05,
    increment: 0.005,
  });
  return rates.map(rate => ({
    value: rate,
    label: `${(rate * 100).toFixed(1)}%`,
  }));
};

const SavingsCalculator = ({ defaultValue, calculatorTitle }) => {
  const [commissionRate, setCommissionRate] = useState(0.015);
  const [valueCallback, setValueCallback] = useState(270000);

  const getPageContext = () => {
    const page = window.location.pathname;
    if (page) {
      if (page === '/') return 'Homepage';
      return page;
    }
    return '';
  };

  const sendSliderEvent = () => {
    createEvent({
      category: 'Savings slider interaction',
      action: getPageContext(),
      label: `House price: ${valueCallback}, commission: ${commissionRate}, savings: ${formatCurrency(
        Math.round(valueCallback * commissionRate)
      )}`,
    });
  };

  return (
    <Container>
      <Box>
        <CalcTitle>{calculatorTitle}</CalcTitle>
      </Box>
      <SavingsBox>
        <SavingText>
          {formatCurrency(Math.round(valueCallback * commissionRate))}
        </SavingText>
      </SavingsBox>
      <Box mb={4}>
        <Text ext>Slide to set your property value:</Text>
      </Box>
      <Box mt={4} mb={3}>
        <Slider
          increments={range}
          numberOnThumb
          setValueCallback={setValueCallback}
          initialSliderPos={defaultValue}
          sendSliderEvent={sendSliderEvent}
        />
      </Box>
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
      >
        <Text styleType="body" ext>
          Savings based on a high street commission of
        </Text>
        <SelectWrapper>
          <ComissionSelect
            value={commissionRate}
            placeholder="Rate..."
            onChange={rate => {
              setCommissionRate(rate);
              sendSliderEvent();
            }}
            options={getCommissionRates()}
          />
        </SelectWrapper>
      </Flex>
    </Container>
  );
};

SavingsCalculator.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  calculatorTitle: PropTypes.string,
};

SavingsCalculator.defaultProps = {
  calculatorTitle: 'Go on Strike and you could save',
};

export default SavingsCalculator;
