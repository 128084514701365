import styled from 'styled-components';
import { rem } from 'polished';
import { Text, Select, Box } from '@strikelabs/luna';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.rg};
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => theme.spacing.lg};
    width: 517px;
  }
`;

export const CalcTitle = styled(Text).attrs({ as: 'h3', ext: true })`
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  letter-spacing: -0.15px;
  font-weight: 500;

  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(36)};
    line-height: ${rem(42)};
    letter-spacing: -0.22px;
  }
`;

export const SavingsBox = styled(Box).attrs({
  mt: [3, 3, 3, 4],
  mb: [3, 3, 3, 5],
  p: 0,
})`
  background: ${({ theme }) => theme.colors.accent};
  width: fit-content;
`;

export const PriceContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.accent};
  margin-right: -${rem(60)};

  ${({ theme }) => theme.media.desktop} {
    margin-right: -${rem(80)};
  }
`;

export const SelectWrapper = styled.div`
  width: ${rem(150)};
  margin-top: ${({ theme }) => theme.spacing.sm};

  li {
    &:hover {
      color: ${({ theme }) => theme.colors.bodyText};
    }
  }

  ul {
    max-height: ${rem(150)};
    overflow: auto;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-left: ${({ theme }) => theme.spacing.sm};
    margin-top: 0;
  }
`;

export const ComissionSelect = styled(Select)`
  border-radius: 4px;
`;

export const SavingText = styled(Text).attrs({
  as: 'h2',
  ext: true,
  bold: true,
})`
  font-size: ${rem(60)};
  line-height: ${rem(66)};
  letter-spacing: -0.38px;
`;
