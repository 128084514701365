import React from 'react';

import { TYPES } from 'schemas';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import SavingsSlider from 'components/SavingsSlider';
import SimilarProperties from 'components/SimilarProperties';
import BrandImg from 'images/strike-brand.png';
import {
  Intro,
  LifeInCity,
  GetYourPropertyValued,
  AgentComparisons,
  Stats,
  FAQs,
} from 'components/Pages/EstateAgentsInLocation/Sections';
import CopyForManchester from 'components/Pages/EstateAgentsInLocation/Sections/LifeInCity/Copy/CopyForManchester';

const EstateAgentsInManchester = () => {
  const images = {
    portrait: {
      src: 'manchester-ivy.png',
      alt: 'The Ivy, Manchester',
    },
    landscape: {
      src: 'st-peters-square.jpg',
      alt: "St Peter's Square",
    },
  };
  return (
    <Layout>
      <SEO
        title="Strike - Estate Agents In Manchester. Sell your house for FREE"
        description="Sell Your House For Free In Manchester With A Simple And Transparent Online Estate Agency Service. Go On Strike. Strike A Better Deal."
        canonical="/estate-agents-in-manchester"
        metaImage={BrandImg}
        schemaType={TYPES.HOME}
      />
      <Hero
        image="manchester-tram.png"
        imageAlt="Manchester tram"
        title="Looking for an estate agent in Manchester?"
        subtitle="We've got you covered"
      />
      <Intro />
      <AgentComparisons city="Manchester" />
      <SavingsSlider
        title="Strike could save you thousands compared to high street agents"
        darkMode
        defaultValue={0.17}
      />
      <Stats />
      <SimilarProperties city="Manchester" id={253865} />
      <LifeInCity city="Manchester" images={images}>
        <CopyForManchester />
      </LifeInCity>
      <GetYourPropertyValued />
      <FAQs />
    </Layout>
  );
};

export default EstateAgentsInManchester;
