import React from 'react';
import { Text } from '@strikelabs/luna';

const CopyforManchester = () => (
  <>
    <Text as="p" ext mb={2}>
      Whether you&apos;re looking to grab a bite at the Corn Exchange or in the
      Northern Quarter, or find a gig at Gorilla, Band on the Wall, the Albert
      Hall, or YES &mdash; Manchester is known for its nightlife, packed with
      independent restaurants and venues.
    </Text>
    <Text as="p" ext mb={2}>
      Of course, the footies among you may be aware that there are two &mdash;
      yes, two &mdash; major clubs, with Manchester City and Manchester United
      both calling the area home (and don&apos;t miss the National Football
      Museum, a must for all real fans).
    </Text>
    <Text as="h4" bold ext underline mb={2}>
      Great for families
    </Text>
    <Text as="p" ext mb={2}>
      For young families &mdash; or couples starting to think about schools
      &mdash; Manchester has a lot to offer. Primaries like New Islington Free
      School, St Ann&apos;s RC Primary School, Ashbury Meadow Primary School and
      Abbott Community Primary School, all come with great reviews and high
      ratings, and their secondary schools shine too. There are a range of
      grammar schools &mdash; including Manchester Grammar School, William
      Hulme&apos;s Grammar School, Stretford Grammar School and Withington High
      School for Girls &mdash; and outstanding-rated comprehensives like Trinity
      CofE High School, The King David High School, Melland High School and
      Levenshulme High School.
    </Text>
    <Text as="h4" ext bold underline mb={2}>
      A university hub
    </Text>
    <Text as="p" ext mb={2}>
      Of course, you can&apos;t forget the universities. Manchester has a
      thriving student population, which helps ensure the city&apos;s dynamic
      edge, and The University of Manchester and Manchester Metropolitan
      University both draw students from around the country and around the
      world. There&apos;s also the celebrated Royal Northern College of Music,
      and other major universities in the area &mdash; University of Salford,
      University of Bolton and University Academy 92 , just to name a few.
    </Text>
    <Text as="p" ext mb={2}>
      As you can see, there are a lot of reasons people love to live in
      Manchester &mdash; and a lot of reasons Strike is proud to be a Manchester
      estate agent.
    </Text>
    <Text as="h4" ext underline bold mb={2}>
      A strong investment
    </Text>
    <Text as="p" ext mb={2}>
      Plus, more and more people are catching on to what Manchester has to
      offer. An affordable, bustling city with nature at its doorstep?
    </Text>
    <Text as="p" ext mb={2}>
      This area has proven itself to be a great investment, for home owners and
      investors alike. Just take a look at what&apos;s on offer.
    </Text>
  </>
);

export default CopyforManchester;
