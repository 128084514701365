import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ to, children, onClick }) => {
  return (
    <a href={to} onClick={onClick}>
      {children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  onClick: () => null,
};

export default Link;
