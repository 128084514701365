import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@strikelabs/luna';

import Image from 'components/Image';
import SavingsCalculator from './SavingsCalculator';
import {
  Outer,
  Inner,
  ColumnWrapper,
  InnerContainer,
  ImageContainer,
} from './style';

const SavingsSlider = ({
  title,
  darkMode,
  imgSrc = darkMode
    ? 'illustrations/selling-dark.png'
    : 'illustrations/selling.png',
  defaultValue,
  calculatorTitle,
}) => {
  return (
    <Outer darkMode={darkMode}>
      <Inner>
        <ColumnWrapper>
          <InnerContainer mb={[4, 4, 4, 0]} left>
            <ImageContainer>
              <Image src={imgSrc} alt="Selling illustration" />
            </ImageContainer>
            <Text as="h2" ext bold color={darkMode ? 'white' : 'inherit'}>
              {title}
            </Text>
          </InnerContainer>
          <InnerContainer>
            <SavingsCalculator
              defaultValue={defaultValue}
              calculatorTitle={calculatorTitle}
            />
          </InnerContainer>
        </ColumnWrapper>
      </Inner>
    </Outer>
  );
};

SavingsSlider.propTypes = {
  title: PropTypes.string.isRequired,
  darkMode: PropTypes.bool,
  imgSrc: PropTypes.string,
  defaultValue: PropTypes.number,
  calculatorTitle: PropTypes.string,
};

SavingsSlider.defaultProps = {
  darkMode: false,
  defaultValue: 0.21,
};

export default SavingsSlider;
